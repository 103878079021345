import * as CookieConsent from "vanilla-cookieconsent";
import '@orestbida/iframemanager/dist/iframemanager.js';

const im = iframemanager();

const imConfig = {
  currLang: 'de',
  onChange: ({ changedServices, eventSource }) => {
    if (eventSource.type === 'click') {

      const servicesToAccept = [
        ...CookieConsent.getUserPreferences().acceptedServices['media'],
        ...changedServices,
      ];
      CookieConsent.acceptService(servicesToAccept, 'media');
    }

    if (eventSource.type === 'click' && eventSource.service == "personio" && eventSource.action == "accept") {
      CookieConsent.acceptCategory('personio');
    }
  },
  services: {
    personio: {
      embedUrl: '{data-id}',
      iframe: {
        onload: (dataId, setThumbnail) => {
          window.addEventListener(
            'message',
            function (e) {
              if (e && e.data) {
                var eventName = e.data[0]
                var data = e.data[1]
                var wrapper = document.querySelector('#personio-wrapper');
                var iframe = document.querySelector('#personio-iframe');

                if (eventName === 'setHeight') {
                  wrapper.style.height = data + 'px';
                  iframe.style.height = data + 'px';
                  // window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
            },
            false
          );
        }
      },
      languages: {
        de: {
          notice: 'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://msb.jobs.personio.de/privacy-policy?language=de" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von personio.de.',
          loadBtn: 'Einmal laden',
          loadAllBtn: "Nicht nochmal fragen",
        }
      },
    },
    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
      iframe: {
        allow:
          'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },
      languages: {
        de: {
          notice: 'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: "Nicht nochmal fragen",
        }
      },
    },
    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      iframe: {
        allow: 'fullscreen; picture-in-picture;',
      },
      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`;
        const response = await (await fetch(url)).json();
        const thumbnailUrl = response[0]?.thumbnail_large;
        thumbnailUrl && setThumbnail(thumbnailUrl);
      },
      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: "Nicht nochmal fragen",
        },
      },
    },
  },
}

const ccConfig = {
  currentLang: 'de',
  mode: 'opt-in',
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  onChange: ({ cookie, changedCategories, changedServices }) => {
    if (cookie.categories.includes('personio')) {
      im.acceptService('personio');
    } else {
      im.rejectService('personio')
    }
  },
  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    personio: {
      readOnly: false,
      enabled: false,
      label: 'Personio',
    },
    media: {
      enabled: false,
      services: {
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => {
            im.acceptService('youtube');
          },
          onReject: () => im.rejectService('youtube'),
        },
        vimeo: {
          label: 'Vimeo Embed',
          onAccept: () => {
            im.acceptService('vimeo');
          },
          onReject: () => im.rejectService('vimeo'),
        },
      },
    }
  },

  language: {
    default: 'de',
    translations: {
      de: {
        "consentModal": {
          "label": "Cookie-Einwilligung",
          "title": "Wir verwenden Cookies.",
          "description": "Damit können wir Dir audiovisuelle Formate und einige Funktionen für ein ansprechendes Webangebot ermöglichen. Einige der Cookies sind notwendig für die Funktion der Website, andere helfen uns, die Website interaktiver und ansprechender zu gestalten. Um unseren eigenen Ansprüchen beim Datenschutz gerecht zu werden, erfassen wir lediglich anonymisierte Nutzer*innendaten mit Matomo.",
          "acceptAllBtn": "Alle akzeptieren",
          "closeIconLabel": "Alle ablehnen und schließen",
          "acceptNecessaryBtn": "Alle ablehnen",
          "showPreferencesBtn": "Einstellungen verwalten",
          "footer": "<a href=\"/datenschutz\">Datenschutzerklärung</a>"
        },
        "preferencesModal": {
          "title": "Cookie-Einwilligung",
          "acceptAllBtn": "Alle akzeptieren",
          "acceptNecessaryBtn": "Alle ablehnen",
          "savePreferencesBtn": "Einstellungen speichern",
          "closeIconLabel": "Modal schließen",
          "serviceCounterLabel": "Service|Dienste",
          "sections": [
            {
              "title": "Hat jemand ... Cookies gesagt?",
              "description": "Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere <a href=\"/datenschutz\">Datenschutzerklärung</a>."
            },
            {
              "title": "Technisch notwendige Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
              "description": "Notwendige Cookies und Dienste ermöglichen grundlegende Funktionen und sind für die bestmögliche Funktion der Website erforderlich.",
              "linkedCategory": "necessary"
            },
            {
              "title": "Personio",
              "description": "Wir gehen mit den Daten von Bewerber*innen sehr sorgfältig um. Dabei hilft uns die Personal-Management Software Personio. Die Daten der Bewerber*innen werden entsprechend EU-DSGVO konform geschützt. Personio wird dafür direkt in die Website eingebunden.",
              "linkedCategory": "personio",
              "cookieTable": {
                "headers": {
                  "name": "Cookie",
                  "Service": "Service",
                  "description": "Beschreibung"
                }
              }
            },
            {
              "title": "Media",
              "description": "Auf unserer Website binden wir Inhalte von Drittanbietern zur Medienwiedergabe ein. Werden die Inhalte angezeigt, werden personenbezogene Daten an den jeweiligen Anbieter übertragen, ggf. werden auch weitere Cookies gesetzt.",
              "linkedCategory": "media",
              "cookieTable": {
                "headers": {
                  "name": "Cookie",
                  "Service": "Service",
                  "description": "Beschreibung"
                }
              }
            }
          ]
        }
      }
    },
  },
};


im.run(imConfig);
CookieConsent.run(ccConfig);

window.initCookieConsent = () => {
  im.run(imConfig);
  CookieConsent.run(ccConfig);
}