import '../css/app.css'
import 'lazysizes'
import Alpine from 'alpinejs'
import baguetteBox from 'baguettebox.js'
import Parallax from 'parallax-js'
import './cookie3.js'

window.Alpine = Alpine

Alpine.data('map', () => ({
  items: [],
  filter: 0,
  init (locations) {
    this.items = locations
  },
  get result () {
    if (this.filter === 0) {
      return this.items
    } else {
      return this.items.filter((item) => item.cat.includes(this.filter))
    }
  },
  setFilter (catId) {
    this.filter = this.filter == catId ? 0 : catId
  },
}))

Alpine.data('calculator', ({
  minimumAmount,
  incomeLimit,
  cateringFee,
  materialFee,
  incomePercent,
  minimumAmountOberschule,
  incomeLimitOberschule,
  cateringFeeOberschule,
  materialFeeOberschule,
  incomePercentOberschule
}) => ({
  taxableIncome: '0',
  minijobIncome: '0',
  childBenefit: '0',
  otherIncome: '0',
  chargedIncome: 0,
  calculatedSchoolFees: 0,
  rawCalculatedSchoolFees: 0,
  calculatedSchoolFeesWithExtras: 0,
  schoolType: 'grundschule',
  // Diese Werte werden in Craft gesetzt
  minimumAmount: minimumAmount,
  incomeLimit: incomeLimit,
  cateringFee: cateringFee,
  materialFee: materialFee,
  incomePercent: incomePercent,

  // Update the values when the school type changes
  updateSchoolType () {
    if (this.schoolType === 'grundschule') {
      this.minimumAmount = minimumAmount
      this.incomeLimit = incomeLimit
      this.cateringFee = cateringFee
      this.materialFee = materialFee
      this.incomePercent = incomePercent
    } else if (this.schoolType === 'oberschule') {
      this.minimumAmount = minimumAmountOberschule
      this.incomeLimit = incomeLimitOberschule
      this.cateringFee = cateringFeeOberschule
      this.materialFee = materialFeeOberschule
      this.incomePercent = incomePercentOberschule
    }
    this.calculateFees() // recalculate fees after updating school type
  },

  // Update input value and calculate fees
  updateValue (field, value) {
    // Remove non-numeric characters except for commas and dots
    this[field] = value.replace(/[^\d.,]/g, '')
    this.calculateFees() // Call calculateFees on input
  },

  // Format value to Euro currency format
  formatCurrency (field) {
    let value = this[field].replace(/\./g, '').replace(/,/g, '.') // Clean value
    let parsedValue = parseFloat(value)
    this[field] = !isNaN(parsedValue)
      ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(parsedValue)
      : '0,00 €'
  },

  clearCurrencyFormat (field) {
    // Remove Euro sign for easier editing
    this[field] = this[field].replace(/[^\d.,]/g, '')
  },

  // Calculate fees based on inputs
  calculateFees () {
    // Ensure all income fields are valid
    if (!this.isValidIncomeFields()) {
      alert('Bitte Sie Ihr zu versteuerndes Einkommen ein!')
      return
    }

    // Calculate the charged income
    const taxable = this.parseCurrency(this.taxableIncome)
    const minijob = this.parseCurrency(this.minijobIncome)
    const childBenefits = this.parseCurrency(this.childBenefit)
    const other = this.parseCurrency(this.otherIncome)
    this.chargedIncome = taxable + minijob + childBenefits + other

    let schulgeld = 0
    if (this.chargedIncome > this.incomeLimit) {
      this.chargedIncome = this.incomeLimit
    }

    // Calculate school fees based on the charged income
    schulgeld = this.chargedIncome * this.incomePercent

    if (schulgeld < this.minimumAmount) {
      schulgeld = this.minimumAmount
    }

    this.calculatedSchoolFees = schulgeld
    this.rawCalculatedSchoolFees = schulgeld
    this.calculatedSchoolFeesWithExtras = schulgeld + this.cateringFee + this.materialFee
    // Format the results for display
    this.chargedIncome = this.formatCurrencyValue(this.chargedIncome)
    this.calculatedSchoolFees = this.formatCurrencyValue(this.calculatedSchoolFees)
    this.calculatedSchoolFeesWithExtras = this.formatCurrencyValue(this.calculatedSchoolFeesWithExtras)
  },

  // Convert currency string to a number
  parseCurrency (value) {
    value = value.replace(/\./g, '').replace(/,/g, '.')
    return parseFloat(value) || 0 // Return 0 if parsing fails
  },

  // Validate input fields
  isValidIncomeFields () {
    return [this.taxableIncome, this.minijobIncome, this.childBenefit, this.otherIncome]
      .every(value => this.parseCurrency(value) >= 0)
  },

  // Format numeric value to Euro currency format
  formatCurrencyValue (value) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
  }
}))

Alpine.start()

document.addEventListener('DOMContentLoaded', () => {
  baguetteBox.run('.baguette', {
    noScrollbars: true,
    async: true,
  })

  const bubbles = document.querySelectorAll('.bubble')
  if (bubbles.length > 0) {
    for (let i = 0; i < bubbles.length; i++) {
      new Parallax(bubbles[i], {
        pointerEvents: true,
        clipRelativeInput: true,
        invertX: false,
        invertY: false,
      })
    }
  }
})
